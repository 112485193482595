<template>
  <div class="layout--full-page">
    <div
      class="
        h-screen
        flex
        w-full
        bg-img
        vx-row
        no-gutter
        items-center
        justify-center
      "
      id="page-login"
    >
      <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
        <vx-card>
          <div slot="no-body">
            <div
              class="
                vx-row
                no-gutter
                justify-center
                items-center
                full-page-bg-color
              "
            >
              <div class="vx-col hidden lg:block lg:w-1/2">
                <img
                  src="@/assets/images/pages/reset-password.png"
                  alt="login"
                  class="mx-auto"
                />
              </div>

              <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
                <div class="p-8 login-tabs-container">
                  <div class="vx-card__title mb-4">
                    <h4 class="mb-4">{{ $t("main.resetPassword") }}</h4>
                  </div>
                  <vs-tabs>
                    <vs-tab :label="$t('main.new_password')">
                      <form @keyup.enter="savePassword">
                        <!-- / new  Pass -->
                        <div class="mt-8">
                          <label class="w-full block text-sm">{{
                            $t("main.password")
                          }}</label>
                          <vx-input-group class="w-full">
                            <vs-input
                              v-validate="'required|min:6'"
                              :type="showPassword ? 'text' : 'password'"
                              name="password"
                              icon="icon-lock"
                              icon-pack="feather"
                              v-model="newPassword"
                              ref="password"
                            />

                            <template slot="append">
                              <div class="append-text btn-addon">
                                <vs-button
                                  color="primary"
                                  type="border"
                                  @click="showPassword = !showPassword"
                                  icon="icon-eye"
                                  icon-pack="feather"
                                ></vs-button>
                              </div>
                            </template>
                          </vx-input-group>
                          <span class="text-danger text-sm w-full">{{
                            errors.first("password")
                          }}</span>
                        </div>
                        <!-- / new  Pass -->
                        <!-- Confirm Pass -->
                        <div class="mt-8">
                          <label class="w-full block text-sm">{{
                            $t("main.confirm_passowrd")
                          }}</label>
                          <vx-input-group class="w-full">
                            <vs-input
                              v-validate="'required|confirmed:password'"
                              :type="showPassword ? 'text' : 'password'"
                              name="confirmPassword"
                              icon="icon-lock"
                              icon-pack="feather"
                              v-model="confirmPassword"
                            />

                            <template slot="append">
                              <div class="append-text btn-addon">
                                <vs-button
                                  color="primary"
                                  type="border"
                                  @click="showPassword = !showPassword"
                                  icon="icon-eye"
                                  icon-pack="feather"
                                ></vs-button>
                              </div>
                            </template>
                          </vx-input-group>
                          <span
                            class="text-danger text-sm w-full"
                            v-show="errors.has('confirmPassword')"
                            >{{
                              $t("main.password_confirmation_does_not_match")
                            }}</span
                          >
                        </div>
                        <!-- /Confirm Pass -->
                        <vs-button
                          @click="savePassword"
                          :disabled="!validateForm"
                          class="
                            block
                            mr-0
                            ml-auto
                            px-4
                            w-full
                            md:w-auto
                            mt-8
                            md:mb-0
                          "
                          >{{ $t("main.save") }}</vs-button
                        >
                      </form>
                    </vs-tab>
                  </vs-tabs>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const ForgetPasswordRepository = RepositoryFactory.get('AuthRepository', 'ForgetPasswordRepository')

export default {
  created () {
    this.userId = this.$route.params.id
  },
  data () {
    return {
      newPassword:null,
      confirmPassword: null,
      showPassword:false,
      userId:null
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.newPassword && this.confirmPassword && this.newPassword === this.confirmPassword
    }
  },
  methods: {
    savePassword () {
      ForgetPasswordRepository
        .resetPassword({
          password:this.newPassword,
          password_confirmation:this.confirmPassword,
          token:this.$route.params.token
        })
        .then(res => {
          this.successMsg(
            this.$t('auth.We_saved_new_password'),
            this.$t('auth.recover_success')
          )
          this.$router.push({name:'login'})

        })
        .catch(err => {
          // console.log("error response" + JSON.stringify(err), err.errors);
          this.errMsg(err.data.errors || error)
        })

    }
  }
}
</script>
